import "bootstrap/dist/css/bootstrap.min.css";
import "./OurGames.css";
import React from "react";
import useScreenHeight from "../../utils/UseScreenHeight"; // Import the custom hook

import handy from "../../Assets/Games/Game Icon (1).png"; // Assuming you have a logo image here
import wrt from "../../Assets/Games/WRT 2 Icon 1024.png"; // Assuming you have a logo image here
import TTS from "../../Assets/Games/Logo TTS 1024 Final.png"; // Assuming you have a logo image here
import wrtSatu from "../../Assets/Games/unnamed.webp"; // Assuming you have a logo image here

const Games = () => {
  const screenHeight = useScreenHeight(); // Use the custom hook

  return (
    <div
      className="app-header-space d-flex services-container justify-content-center align-items-center"
      style={{ height: `${screenHeight}px` }}
    >
      <div className="text-center">
        <h2 className="">
          <strong className="title-hero title-services text-nowrap">
            OUR GAMES
          </strong>
        </h2>

        <div className="container h-75 w-75 justify-content-center align-items-center">
          <div className="row justify-content-center align-items-center">
            {[
              {
                img: handy,
                title: "Handy Farm:<br/> Roguelike",
                link: "https://play.google.com/store/apps/details?id=com.anoa.handyfarm",
              },
              {
                img: wrt,
                title: "Water Ring <br/> Toss 2",
                link: "https://play.google.com/store/apps/details?id=com.Anoa.WRT2",
              },
              {
                img: TTS,
                title: "TTS Viral",
                link: "https://play.google.com/store/apps/details?id=com.anoa.ttsviral",
              },
              {
                img: wrtSatu,
                title: "Water Ring <br/> Toss 3D",
                link: "https://play.google.com/store/apps/details?id=com.Anoa.WRT3D",
              },
            ].map((service, index) => (
              <div
                className="col-6 col-lg-3 d-flex justify-content-center"
                key={index}
              >
                <div className="card  games-card d-flex border-0 justify-content-center align-items-center">
                  <img
                    src={service.img}
                    className="img-fluid"
                    style={{ borderRadius: "20px", maxHeight: "178px" }}
                    alt={service.title}
                  />
                  <div
                    className="card-body  d-flex justify-content-center align-items-center"
                    style={{ padding: "10px 2px" }}
                  >
                    <ul className="list-group list-group-flush border-0">
                      <li
                        className="list-group-item border-0 text-center text-nowrap"
                        style={{ minHeight: "60px", padding: "0px" }} // Ensure title area has a fixed height
                        dangerouslySetInnerHTML={{ __html: service.title }}
                      ></li>

                      <li
                        className="list-group-item border-0 text-center"
                        style={{ minHeight: "60px", padding: "0px" }}
                      >
                        <a href={service.link} target="blank">
                          <img
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            className="h-100 w-100"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Games;
