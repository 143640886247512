import React, { useState, useEffect } from "react";
import "./Header.css";
import logoImage from "../../Assets/Anoa-Interactive-White-Transparent_Logo.png";
import logoImageMobile from "../../Assets/Icon-Logo-White.png";

const Header = ({ sections }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const checkScreenSize = () => {
    setIsMobile(window.innerWidth < 830);
  };

  const toggleMobileNav = () => {
    setIsMobileNavOpen((prevState) => !prevState);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Function to handle scrolling to a specific section
  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
    setIsMobileNavOpen(false); // Close mobile nav after clicking a link
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logoImage} alt="Logo" />
      </div>
      <nav className={`nav-links ${isMobileNavOpen ? "open" : ""}`}>
        <ul>
          <li>
            <a href="#about" onClick={() => scrollToSection(sections[1].ref)}>
              ABOUT
            </a>
          </li>
          <li>
            <a
              href="#services"
              onClick={() => scrollToSection(sections[4].ref)}
            >
              SERVICES
            </a>
          </li>
          <li>
            <a
              href="#partners"
              onClick={() => scrollToSection(sections[5].ref)}
            >
              PARTNERS
            </a>
          </li>
          <li>
            <a href="#games" onClick={() => scrollToSection(sections[6].ref)}>
              GAMES
            </a>
          </li>
          <li>
            <a href="#contact" onClick={() => scrollToSection(sections[7].ref)}>
              CONTACT
            </a>
          </li>
        </ul>
      </nav>
      <div
        className={`hamburger ${isMobileNavOpen ? "open" : ""}`}
        onClick={toggleMobileNav}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </header>
  );
};

export default Header;
