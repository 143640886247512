import "bootstrap/dist/css/bootstrap.min.css";
import "./TeamMember.css";
import React from "react";
import useScreenHeight from "../../utils/UseScreenHeight"; // Import the custom hook

import danendra from "../../Assets/Anoa Member - Danendra.png"; // Assuming you have a logo image here
import auryan from "../../Assets/Anoa Member - Auryan.png"; // Assuming you have a logo image here
import ibnu from "../../Assets/Anoa Member - Ibnu.png"; // Assuming you have a logo image here
import andrew from "../../Assets/Anoa Member - Andrew.png"; // Assuming you have a logo image here

const TeamMember = () => {
  const screenHeight = useScreenHeight(); // Use the custom hook

  return (
    <div
      className="d-flex app-header-space team-member-container justify-content-center align-items-center"
      style={{ height: `${screenHeight}px` }}
    >
      <div className="text-center">
        <h2 class="my-4">
          {" "}
          <strong className="title-hero title-team text-nowrap">
            TEAM MEMBER
          </strong>
        </h2>

        <div class="container vw-100 justify-content-center align-items-center">
          <div className="row team-photo justify-content-center align-items-center">
            {/* Column for each team member */}
            <div className="col-6 col-lg-2 mb-4">
              <div className="card border-0 justify-content-center align-items-center">
                <img
                  src={danendra}
                  className="card-img-top img-fluid"
                  alt="Danendra"
                />
                <div className="card-body">
                  <h5 className="card-title">Danendra</h5>
                  <p className="card-text text-wrap">
                    Founder/<br></br>
                    Programmer
                  </p>
                  <a
                    href="https://www.linkedin.com/in/gde-danendra-arya-nugraha-b9b26b107/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-linkedin fa-3x linkedin"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-6  col-lg-2 mb-4">
              <div className="card border-0 justify-content-center align-items-center">
                <img
                  src={auryan}
                  className="card-img-top img-fluid"
                  alt="Auryan"
                />
                <div className="card-body">
                  <h5 className="card-title">Auryan</h5>
                  <p className="card-text text-wrap">
                    Co-founder/<br></br>
                    Programmer
                  </p>
                  <a
                    href="https://www.linkedin.com/in/auryan-pratama-80b928238/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-linkedin fa-3x linkedin"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-6  col-lg-2 mb-4">
              <div className="card  border-0 justify-content-center align-items-center">
                <img src={ibnu} className="card-img-top img-fluid" alt="Ibnu" />
                <div className="card-body">
                  <h5 className="card-title">Ibnu</h5>
                  <p className="card-text text-wrap">
                    Co-founder/<br></br>
                    Artist
                  </p>
                  <a
                    href="https://www.linkedin.com/in/ibnu-fadhil-muhammad-020540253/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-linkedin fa-3x linkedin"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-6 col-lg-2 mb-4">
              <div className="card  border-0 justify-content-center align-items-center">
                <img
                  src={andrew}
                  className="card-img-top img-fluid"
                  alt="Andrew"
                />
                <div className="card-body">
                  <h5 className="card-title">Andrew</h5>
                  <p className="card-text text-wrap">
                    Co-founder/ <br></br>GameDesigner
                  </p>
                  <a
                    href="https://www.linkedin.com/in/andrewmh3/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-linkedin fa-3x linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
