import React, { useState, useEffect } from "react";
import "./Carousel.css"; // We'll define the styles in this file
import image1 from "../../Assets/Key-Visual-HI-RES-Crop.png";
import image2 from "../../Assets/Banner.png";
import image3 from "../../Assets/Playstore_Banner.jpg";
import UseScreenHeight from "../../utils/UseScreenHeight"; // Import the custom hook

import ScrollDownButton from "../ScrollDownButton";
const Carousel = () => {
  const screenHeight = UseScreenHeight(); // Use the custom hook

  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    {
      img: image1,
      title: "HANDY FARM",
      objectPosition: "right",
      description:
        "Handy Farm is a Roguelike Farming & Dating simulator games where you manage a farm and gain skills as you help the townsfolk. Plant seed, pay the rent, gain new skills, and unlock unique Ending with one of the townsfolk.",
    },
  ];
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        height: `${screenHeight}px`,
      }}
    >
      <div
        className="carousel-images"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div className="carousel-slide" key={index}>
            <img
              className="carousel-image"
              src={slide.img}
              alt={slide.title}
              style={{ objectPosition: slide.objectPosition }}
            />
            <div className="carousel-overlay"></div>
            <div className="carousel-content">
              <h2 className="carousel-title">{slide.title}</h2>
              <p className="carousel-description">{slide.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Button above the title */}
      {/* <div className="carousel-button-container">
        <button className="carousel-button">GET STARTED NOW</button>
      </div>*/}
      {/* Title Navigation */}
      {/* <div className="title-container">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`title ${currentIndex === index ? 'active' : ''}`}
            onClick={() => handleTitleClick(index)} // Navigate to slide
          >
            {slide.title}
          </div>
        ))}
      </div>*/}

      <ScrollDownButton />
    </div>
  );
};

export default Carousel;
