import React, { useEffect } from "react";
import Header from "./Components/Header";
import Carousel from "./Components/Carousel";
import About from "./Components/About/About";
import TeamMember from "./Components/TeamMember";
import Achievments from "./Components/Achievments";
import Services from "./Components/Services";
import Partners from "./Components/Partners";
import Games from "./Components/OurGames";
import Footer from "./Components/Footer";
import "./App.css";

const App = () => {
  const sections = [
    { id: 1, ref: React.createRef() },
    { id: 2, ref: React.createRef() },
    { id: 3, ref: React.createRef() },
    { id: 4, ref: React.createRef() },
    { id: 5, ref: React.createRef() },
    { id: 6, ref: React.createRef() },
    { id: 7, ref: React.createRef() },
    { id: 8, ref: React.createRef() },
  ];

  return (
    <div>
      {/* Pass sections array to Header */}
      <Header sections={sections} />

      {/* Carousel section */}
      <section ref={sections[0].ref}>
        <Carousel />
      </section>

      {/* About section */}
      <section ref={sections[1].ref}>
        <About />
      </section>

      <section ref={sections[2].ref}>
        <TeamMember />
      </section>

      <section ref={sections[3].ref}>
        <Achievments />
      </section>

      <section ref={sections[4].ref}>
        <Services />
      </section>

      <section ref={sections[5].ref}>
        <Partners />
      </section>

      <section ref={sections[6].ref}>
        <Games />
      </section>

      <section ref={sections[7].ref}>
        <Footer />
      </section>
    </div>
  );
};

export default App;
