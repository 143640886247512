import React from 'react';
import './ScrollDownButton.css';

const ScrollDownButton = () => {
  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight, // Scroll to the next section
      behavior: 'smooth',      // Smooth scroll effect
    });
  };

  return (
    <div className="scroll-down-container" onClick={handleScroll}>
 {/* Static Font Awesome arrow */}
      <span className="scroll-text"><i className="fas fa-arrow-down"></i>&nbsp; Scroll Down</span>     
    </div>
  );
};

export default ScrollDownButton;
