import React, { useEffect, useRef, useState } from "react";
import "./Achievment.css";
import BadgeGameSeed from "../../Assets/gameseedBadge.png";
import BadgeAki from "../../Assets/badgeAki.png";
import BadgeTiltSpot from "../../Assets/tiltSpot.png";
import BadgeGJ from "../../Assets/badgeGj+.png";
import useScreenHeight from "../../utils/UseScreenHeight";

const ImageList = () => {
  const screenHeight = useScreenHeight(); // Use the custom hook

  const [badges] = useState([
    { src: BadgeGameSeed, title: "3rd Place", year: "Gameseed 2023" },
    {
      src: BadgeGameSeed,
      title: "Nominee /n Best Game",
      year: "Gameseed 2024",
    },
    { src: BadgeTiltSpot, title: "2nd Place", year: "Social Game Jam 2019" },
    { src: BadgeGJ, title: "Best Technical", year: "GameJam+ 2022" },
    { src: BadgeGJ, title: "Nominee Best Game", year: "GameJam+ 2022" },
    { src: BadgeAki, title: "Finalist", year: "AKI /n 2024" },
  ]);

  const scrollWrapperRef = useRef(null);
  const animationRef = useRef(null); // To hold the requestAnimationFrame reference
  const startTimeRef = useRef(null); // To track the start time of animation
  const totalScrollWidthRef = useRef(0); // To keep the total scroll width
  const containerWidthRef = useRef(0); // To keep the container width
  const originalListWrapperRef = useRef(null);
  useEffect(() => {
    const scrollWrapper = scrollWrapperRef.current;
    const totalScrollWidth = scrollWrapper.scrollWidth;
    const containerWidth = scrollWrapper.parentElement.offsetWidth;
    const originalListWrapperWidth =
      originalListWrapperRef.current.offsetWidth * badges.length * 2;
    // Set references for width values
    totalScrollWidthRef.current = totalScrollWidth;
    containerWidthRef.current = containerWidth;

    const duration = 150000; // Animation duration in milliseconds

    const animate = (timestamp) => {
      if (!startTimeRef.current) startTimeRef.current = timestamp;

      const progress = (timestamp - startTimeRef.current) % duration;
      const scrollPosition = (progress / duration) * totalScrollWidth;

      // Move the scroll-wrapper to the left
      scrollWrapper.style.transform = `translateX(${-scrollPosition}px)`;

      // Check if the original image list is completely out of view
      if (scrollPosition >= totalScrollWidth / 2) {
        // If so, reset the original list's position
        scrollWrapper.style.transition = "none"; // Disable transition for immediate reset
        scrollWrapper.style.transform = `translateX(0)`;
        startTimeRef.current = timestamp; // Reset start time for seamless looping
        setTimeout(() => {
          scrollWrapper.style.transition = ""; // Re-enable transition after reset
        }, 0); // Timeout to ensure the transition is reset
      }

      // Continue the animation
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    // Cleanup function to cancel the animation frame when the component unmounts
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [badges]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: `${screenHeight}px` }}
    >
      <div className=" text-center">
        <h2 class="my-4">
          {" "}
          <strong className="title-hero title-team text-nowrap">
            ACHIEVEMENTS
          </strong>
        </h2>

        <div className="scroll-container justify-content-center align-items-center">
          <div className="scroll-wrapper d-flex " ref={scrollWrapperRef}>
            {/* Render the original list */}
            {badges.map((badge, index) => (
              <div
                key={index}
                className="img-container"
                ref={originalListWrapperRef}
              >
                <img
                  src={badge.src}
                  alt={`Badge ${index + 1}`}
                  className="img-fluid"
                />
                <div class="container text-center justify-content-center align-items-center">
                  <div class="row">
                    <div class="col col-fix-height-sm text-center">
                      <h6 className="mt-2 text-wrap">
                        {badge.title.split("/n").map((line, i) => (
                          <span key={i} className="align-top">
                            {line}
                            {i < badge.title.split("/n").length - 1 && <br />}
                          </span>
                        ))}
                      </h6>
                    </div>
                    <div class="w-100"></div>

                    <div class="col col-fix-height">
                      {" "}
                      {badge.year.split("/n").map((line, i) => (
                        <span key={i} className="align-top">
                          {line}
                          {i < badge.year.split("/n").length - 1 && <br />}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* Duplicate the list for seamless looping */}
            {badges.map((badge, index) => (
              <div key={`duplicate-${index}`} className="img-container">
                <img
                  src={badge.src}
                  alt={`Badge Duplicate ${index + 1}`}
                  className="img-fluid"
                />
                <div class="container text-center justify-content-center align-items-center">
                  <div class="row">
                    <div class="col col-fix-height-sm text-center">
                      <h6 className="mt-2 text-wrap ">
                        {badge.title.split("/n").map((line, i) => (
                          <span key={i} className="align-top">
                            {line}
                            {i < badge.title.split("/n").length - 1 && <br />}
                          </span>
                        ))}
                      </h6>
                    </div>
                    <div class="w-100"></div>

                    <div class="col col-fix-height">
                      {badge.year.split("/n").map((line, i) => (
                        <span key={i} className="align-top">
                          {line}
                          {i < badge.year.split("/n").length - 1 && <br />}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageList;
