import { useState, useEffect } from "react";

// Custom hook to get screen height only for desktop screens
const useScreenHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Function to update the height only if the screen is desktop-sized
    const updateHeight = () => {
      const isDesktop = window.innerWidth >= 1024;
      if (isDesktop && height !== window.innerHeight) {
        setHeight(window.innerHeight);
      }
    };

    // Set initial height if on desktop
    updateHeight();

    // Add resize event listener
    window.addEventListener("resize", updateHeight);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [height]);

  return height;
};

export default useScreenHeight;
