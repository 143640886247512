import "bootstrap/dist/css/bootstrap.min.css";
import "./Services.css";
import React from "react";
import useScreenHeight from "../../utils/UseScreenHeight"; // Import the custom hook

import gear from "../../Assets/gamedev Gear.png"; // Assuming you have a logo image here
import console from "../../Assets/console.png"; // Assuming you have a logo image here
import subway from "../../Assets/subway_menu.png"; // Assuming you have a logo image here
import kuas from "../../Assets/kuas.png"; // Assuming you have a logo image here

const Services = () => {
  const screenHeight = useScreenHeight(); // Use the custom hook

  return (
    <div
      className="app-header-space d-flex services-container justify-content-center align-items-center"
      style={{ height: `${screenHeight}px` }}
    >
      <div className="text-center">
        <h2 className="">
          <strong className="title-hero title-services text-nowrap">
            SERVICES
          </strong>
        </h2>

        <div className="container justify-content-center align-items-center">
          <div className="row justify-content-center align-items-center">
            {[
              { img: gear, title: "GAME<br />DEVELOPMENT" },
              { img: console, title: "GAMIFICATION<br />SERVICE" },
              { img: subway, title: "GAME<br />ASSETS" },
              { img: kuas, title: "GRAPHIC<br />DESIGN" },
            ].map((service, index) => (
              <div
                className="col-6 col-lg-3 d-flex justify-content-center mb-4"
                key={index}
              >
                <div className="card custom-card d-flex border-0 justify-content-center align-items-center">
                  <img
                    src={service.img}
                    className="card-img-top img-fluid card-image"
                    alt={service.title}
                  />
                  <div
                    className="card-body d-flex justify-content-center align-items-end h-100 w-100"
                    style={{ padding: "0px" }}
                  >
                    <ul className="list-group list-group-flush border-0">
                      <li
                        className="list-group-item border-0 card-title text-center"
                        dangerouslySetInnerHTML={{ __html: service.title }}
                      ></li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
