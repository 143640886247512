import React, { useEffect, useRef, useState } from "react";
import "./partner.css";
import useScreenHeight from "../../utils/UseScreenHeight";

import gj from "../../Assets/Partner/gj 1.svg";
import indigo from "../../Assets/Partner/Indigo-Game-Startup-Incubations-Banner.png";
import usu from "../../Assets/Partner/Logo_of_North_Sumatra_University.svg.png";
import esportnesia from "../../Assets/Partner/logo-1080x221.png";
import balance from "../../Assets/Partner/4 the balance.png";
import xtiming from "../../Assets/Partner/Logo_finalweb.png";
import ggj from "../../Assets/Partner/ggj00-roundlogo-900x900.png";
import gdmedan from "../../Assets/Partner/Logo GDM Black.png";
import binus from "../../Assets/Partner/Logo_Binus_University.png";
import agate from "../../Assets/Partner/akurat_20190130085459_A0kQr3.webp";
import kemenparekraf from "../../Assets/Partner/LOGO_KEMENPAREKRAF_(BAPAREKRAF).png";
const ImageList = () => {
  const screenHeight = useScreenHeight(); // Use the custom hook

  const [badges] = useState([
    { src: gj, link: "" },
    { src: usu, link: "" },
    { src: indigo, link: "" },
    { src: esportnesia, link: "https://esportsnesia.com" },
    { src: balance, link: "" },
    { src: xtiming, link: "" },
    { src: ggj, link: "" },
    { src: gdmedan, link: "" },
    { src: binus, link: "" },
    { src: agate, link: "" },
    { src: kemenparekraf, link: "" },
  ]);

  const scrollWrapperRef = useRef(null);
  const animationRef = useRef(null); // To hold the requestAnimationFrame reference
  const startTimeRef = useRef(null); // To track the start time of animation
  const totalScrollWidthRef = useRef(0); // To keep the total scroll width
  const containerWidthRef = useRef(0); // To keep the container width
  const originalListWrapperRef = useRef(null);
  useEffect(() => {
    const scrollWrapper = scrollWrapperRef.current;
    const totalScrollWidth = scrollWrapper.scrollWidth;
    const containerWidth = scrollWrapper.parentElement.offsetWidth;
    const originalListWrapperWidth =
      originalListWrapperRef.current.offsetWidth * badges.length * 2;
    // Set references for width values
    totalScrollWidthRef.current = totalScrollWidth;
    containerWidthRef.current = containerWidth;

    const duration = 50000; // Animation duration in milliseconds

    const animate = (timestamp) => {
      if (!startTimeRef.current) startTimeRef.current = timestamp;

      const progress = (timestamp - startTimeRef.current) % duration;
      const scrollPosition = (progress / duration) * totalScrollWidth;

      // Move the scroll-wrapper to the left
      scrollWrapper.style.transform = `translateX(${-scrollPosition}px)`;

      // Check if the original image list is completely out of view
      if (scrollPosition >= totalScrollWidth / 2) {
        // If so, reset the original list's position
        scrollWrapper.style.transition = "none"; // Disable transition for immediate reset
        scrollWrapper.style.transform = `translateX(0)`;
        startTimeRef.current = timestamp; // Reset start time for seamless looping
        setTimeout(() => {
          scrollWrapper.style.transition = ""; // Re-enable transition after reset
        }, 0); // Timeout to ensure the transition is reset
      }

      // Continue the animation
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    // Cleanup function to cancel the animation frame when the component unmounts
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [badges]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: `${screenHeight}px` }}
    >
      <div className=" text-center">
        <h2 class="my-4">
          {" "}
          <strong className="title-hero title-team text-nowrap">
            PARTNERS
          </strong>
        </h2>

        <div className="scroll-container justify-content-center align-items-center">
          <div
            className="scroll-wrapper d-flex justify-content-center align-items-center"
            ref={scrollWrapperRef}
          >
            {/* Render the original list */}
            {badges.map((badge, index) => (
              <div
                key={index}
                className="img-container"
                ref={originalListWrapperRef}
              >
                <a href={badge.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={badge.src}
                    alt={`Badge Duplicate ${index + 1}`}
                    className="img-fluid"
                    style={{ maxHeight: `70px` }}
                  />
                </a>{" "}
                <div class="container text-center justify-content-center align-items-center"></div>
              </div>
            ))}
            {/* Duplicate the list for seamless looping */}
            {badges.map((badge, index) => (
              <div key={`duplicate-${index}`} className="img-container">
                <a href={badge.link} target="_blank" rel="noopener noreferrer">
                  {" "}
                  <img
                    src={badge.src}
                    alt={`Badge Duplicate ${index + 1}`}
                    className="img-fluid"
                    style={{ maxHeight: `70px` }}
                  />
                </a>
                <div class="container text-center justify-content-center align-items-center"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageList;
