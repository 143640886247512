import 'bootstrap/dist/css/bootstrap.min.css';
import './About.css';
import React from 'react';
import useScreenHeight from '../../utils/UseScreenHeight'; // Import the custom hook

const About = () => {

  const screenHeight = useScreenHeight();

return (
    <div className="d-flex justify-content-center align-items-center app-header-space " style={{height:`${screenHeight }px`}}>
      <div className="text-center">
       <strong className="title-hero">ABOUT US</strong>
        <div className="container text-center justify-content-center">
  <div className="row justify-content-center">
    <div className="col-sm-8 about-description" ><p align="justify"><strong>Anoa Interactive</strong> is an Indie game development studio based in Indonesia that comprises a team of multiple passionate individuals. Our studio is dedicated to creating immersive and engaging gaming experiences for players around the world. With a focus on creativity, innovation, and attention to detail, we strive to produce high-quality games that push the boundaries of what is possible in the industry.</p>
    </div>
  </div>
</div>
      </div>
    </div>
);   
};

export default About;
