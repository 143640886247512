import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import React from "react";
import useScreenHeight from "../../utils/UseScreenHeight"; // Import the custom hook

const Footer = () => {
  const screenHeight = useScreenHeight(); // Use the custom hook

  return (
    <div
      className="d-flex app-header-space d-flex flex-column justify-content-end align-items-center"
      style={{ height: `${screenHeight}px` }}
    >
      <div class="container text-center h-100 ">
        <div class=" d-flex row justify-content-center align-items-center h-100 footer-container">
          <div class="col-sm-6 footer-title">
            {" "}
            <span>
              Have a Question? <br></br>Contact us
            </span>
          </div>
          <div class="col-sm-6">
            {" "}
            <ul className="list-group list-group-flush footer-details-container">
              <li
                className="list-group-item border-0 d-flex align-items-center"
                style={{ height: "auto", padding: "20px" }}
              >
                <div className="row">
                  <div className="col-auto d-flex align-items-center">
                    <h1>
                      {" "}
                      <i className="fa-solid fa-location-dot"></i>
                    </h1>
                  </div>
                  <div className="col d-flex align-items-center">
                    <p className="footer-text mb-0">
                      Forum Nine, Jl. Imam Bonjol Dalam No.9 9th Floor, Petisah
                      Tengah, Kec. Medan Petisah, Kota Medan, Sumatera Utara
                      20112
                    </p>
                  </div>
                </div>
              </li>

              <li
                className="list-group-item border-0 d-flex align-items-center"
                style={{ height: "auto", padding: "20px" }}
              >
                <div className="row">
                  <div className="col-auto d-flex align-items-center">
                    <h1>
                      {" "}
                      <i className="fa-solid fa-envelope"></i>
                    </h1>
                  </div>
                  <div className="col d-flex align-items-center">
                    <p className="footer-text mb-0">
                      anoainteractive@gmail.com
                    </p>
                  </div>
                </div>
              </li>
              <li
                className="list-group-item border-0 d-flex align-items-center"
                style={{ height: "auto", padding: "20px" }}
              >
                <div className="row">
                  <div className="col-auto d-flex align-items-center">
                    <h1>
                      {" "}
                      <i className="fa-solid fa-phone"></i>
                    </h1>
                  </div>
                  <div className="col d-flex align-items-center">
                    <p className="footer-text mb-0">
                      061-8010-1324 / +62895-6117-46668{" "}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-dark footer w-100 text-center text-white py-3">
        ©2024 AnoaInteractive
      </div>
    </div>
  );
};

export default Footer;
